<template>
  <el-dialog :visible.sync="stepSwitch" class="stepBox" width="1124px" append-to-body :modal-append-to-body="false" :show-close="false" :close-on-click-modal='false'>
    <svg class="closeIcon" aria-hidden="true" @click="close()">
      <use xlink:href="#iconguanbi" />
    </svg>
    <div class="stepContent">
      <ul class="nav">
        <li v-for="(item,index) in navList" :key="index" @click="changes(index)" :class="{active:index == num}">{{item}}</li>
      </ul>
      <img @click="prev()" :src="this.num==0 ? require(`@/assets/img/console/prev_disable.png`):require(`@/assets/img/console/prev.png`)" alt="" class="prev"/>
      <img @click="next()" :src="this.num== 2? require(`@/assets/img/console/next_disable.png`):require(`@/assets/img/console/next.png`)" alt="" class="next" />
      <div class="stepCount" v-if="this.num==0" style="margin:100px 0 159px">
        <ul class="stepImg">
          <li class="item">
            <div class="itemImg">
              <svg style="width:42px;height:40px;" aria-hidden="true">
                <use xlink:href="#iconzhuce" />
              </svg>
              <svg-icon class="goldIdentificationStepIcon" svg-name="goldIdentificationStep"></svg-icon>
            </div>
            <p class="itemMsg">注册联盟官网账号</p>
          </li>
          <li class="item">
            <div class="itemImg">
              <svg style="width:36px;height:42px;" aria-hidden="true">
                <use xlink:href="#iconwanshanziliao" />
              </svg>
              <svg-icon class="goldIdentificationStepIcon" svg-name="goldIdentificationStep"></svg-icon>
            </div>
            <p class="itemMsg">完善账户资料</p>
          </li>
          <li class="item">
            <div class="itemImg">
              <svg style="width:38px;height:38px;" aria-hidden="true">
                <use xlink:href="#icondengdai" />
              </svg>
              <svg-icon class="goldIdentificationStepIcon" svg-name="goldIdentificationStep"></svg-icon>
            </div>
            <p class="itemMsg">等待审核通过</p>
          </li>
          <li class="item">
            <div class="itemImg">
              <svg style="width:41px;height:41px;" aria-hidden="true">
                <use xlink:href="#iconbangding" />
              </svg>
            </div>
            <p class="itemMsg">绑定厂商账号</p>
          </li>
        </ul>
      </div>
      <div class="stepCount" v-if="this.num==1" style="margin:53px 0 55px">
        <ul class="stepImg" style="padding: 0 50px;">
          <li class="item">
            <div class="itemImg">
              <svg style="width:45px;height:41px;margin-left:-3px" aria-hidden="true">
                <use xlink:href="#iconfabu" />
              </svg>
              <svg-icon class="goldIdentificationStepIcon2" svg-name="goldIdentificationStep"></svg-icon>
            </div>
            <p class="itemMsg">发布应用</p>
          </li>
          <li class="item">
            <div class="itemImg">
              <svg style="width:38px;height:38px;" aria-hidden="true">
                <use xlink:href="#iconfaqi" />
              </svg>
              <svg-icon class="goldIdentificationStepIcon2" svg-name="goldIdentificationStep"></svg-icon>
            </div>
            <p class="itemMsg">发起认证</p>
          </li>
          <li class="item">
            <div class="itemImg">
              <svg style="width:38px;height:38px;" aria-hidden="true">
                <use xlink:href="#icondengdai" />
              </svg>
              <svg-icon class="goldIdentificationStepIcon2" svg-name="goldIdentificationStep"></svg-icon>
            </div>
            <p class="itemMsg">等待审核结果</p>
          </li>
          <li class="item">
            <div class="itemImg">
              <svg style="width:38px;height:41px;" aria-hidden="true">
                <use xlink:href="#iconshenhe" />
              </svg>
               <svg-icon class="goldIdentificationStepIcon2" svg-name="goldIdentificationStep"></svg-icon>
            </div>
            <p class="itemMsg">审核通过</p>
          </li>
          <li class="item">
            <div class="itemImg">
              <svg style="width:38px;height:41px;" aria-hidden="true">
                <use xlink:href="#iconhuode" />
              </svg>
            </div>
            <p class="itemMsg">获得金标</p>
          </li>
        </ul>
        <ul class="tips">
          <li><strong>发布应用：</strong>请前往OPPO、vivo、小米三家的软件商店发布应用，并保证版本一致；</li>
          <li><strong>重新认证：</strong>认证新应用失败后，请根据认证结果优化应用，并在三家商店发布，然后在联盟官网重新认证；</li>
          <li><strong>金标有效期：</strong>首次获得金标后，金标的有效期为180天。</li>
        </ul>
      </div>
      <div class="stepCount" v-if="this.num==2" style="margin:53px 0 30px">
        <ul class="stepImg">
          <li class="item">
            <div class="itemImg">
              <svg style="width:45px;height:41px;margin-left:-3px;" aria-hidden="true">
                <use xlink:href="#iconfabu" />
              </svg>
              <svg-icon class="goldIdentificationStepIcon" svg-name="goldIdentificationStep"></svg-icon>
            </div>
            <p class="itemMsg">发布应用</p>
          </li>
          <li class="item">
            <div class="itemImg">
              <svg style="width:42px;height:38px;" aria-hidden="true">
                <use xlink:href="#icondengdai" />
              </svg>
              <svg-icon class="goldIdentificationStepIcon" svg-name="goldIdentificationStep"></svg-icon>
            </div>
            <p class="itemMsg">等待认证结果</p>
          </li>
          <li class="item">
            <div class="itemImg">
              <svg style="width:38px;height:38px;" aria-hidden="true">
                <use xlink:href="#iconshenhe" />
              </svg>
              <svg-icon class="goldIdentificationStepIcon" svg-name="goldIdentificationStep"></svg-icon>
            </div>
            <p class="itemMsg">认证通过</p>
          </li>
          <li class="item">
            <div class="itemImg">
              <svg style="width:38px;height:38px;" aria-hidden="true">
                <use xlink:href="#iconhuode"/>
              </svg>
            </div>
            <p class="itemMsg">更新金标有效期</p>
          </li>
        </ul>
        <ul class="tipsBox">
          <li class="itemTips">
            <img src="@/assets/img/console/icon1.png" alt=""/>
            <p>
              当您的应用版本通过认证时，应用将获得金标， 金标有效期最多不超过180天   
            </p>
          </li>
          <li class="itemTips">
            <img src="@/assets/img/console/icon2.png" alt="" />
            <p>
              当您的应用最新版本未通过认证时，应用的金标有效期不发生变化或者变为30天
            </p>
          </li>
        </ul>
      </div>
      <div class="msgBox">所有的认证结果将会通过邮件发送至您的联系人邮箱，请及时关注</div>
    </div>
  </el-dialog>
</template>
<script>

export default {
  props:{
      stepSwitch: {
        type: Boolean,
        default: false
      },
  },
  created(){
  },
  data() {
    return {
      navList:["认证准备","认证新应用","认证新版本"],
      num:0,
    };
  },
  methods: {
    changes(key) {
			this.num = key;
		},
    next(){
      if(this.num!=2){
        this.num=this.num+1
      }
      
    },
    prev(){
       if(this.num!=0){
        this.num=this.num-1
      }
    },
    close(){
      this.$emit('closeStep',false);
      this.$emit('close',true);
      this.num=0;
    }
  }
};
</script>

<style scoped lang="scss">
.stepBox{
  z-index: 99999!important;
  /deep/ .el-dialog{
    border-radius:10px;
  }
  .closeIcon{
    position: absolute;
    top: 16px;
    right: 18px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .prev{
    position: absolute;
    top: 254px;
    left: 27px;
    cursor: pointer;
  }
  .next{
    position: absolute;
    top: 254px;
    right: 27px;
    cursor: pointer;
  }
  .stepContent{
    padding:0 45px 0 45px;
    height: 500px;
    .nav{
      display: flex;
      padding: 0 120px;
      width: 829px;
      margin: 0 auto;
      justify-content: space-between;
      font-weight: bold;
      color: #000;
      li{
        width: 170px;
        height: 43px;
        border: 2px solid #28262c;
        border-radius: 22px;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      .active{
        background-color:#28262c;
        border: none;
        color: #DED094;
      }
    }
    .stepCount{
      .stepImg{
        display: flex; 
        .item{
          flex: 1;
          .itemImg{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin:29px auto 18px auto;
            width:72px;
            height:72px;
            background:rgba(223,223,223,1);
            border-radius:50%;
            .goldIdentificationStepIcon{
              position: absolute;
              top: 50%;
              right: -160px;
              transform: translateY(-50%);
              width:145px;
              height:12px;
            }
            .goldIdentificationStepIcon2{
              position: absolute;
              top: 50%;
              right: -100px;
              transform: translateY(-50%);
              width:80px;
              height:12px;
            }
          }
          .itemMsg{
            font-size:14px;
            font-weight:bold;
            color:rgba(51,51,51,1);
            text-align: center;
          }
        }   
      }
      .tips{
        width: 783px;
        height: 108px;
        margin: 71px auto 0;
        border: 1px solid #28262c;
        border-radius: 10px;
        color: #333;
        padding: 17px 0 0 61px;
        li{
          line-height: 25px;
        }
      }
      .tipsBox{
        width: 89%;
        display: flex;
        padding: 0 36px;
        margin: 71px auto 0;
        justify-content: space-between;
        .itemTips{
          width: 395px;
          height: 80px;
          padding: 10px;
          border-radius: 5px;
          display: flex;
          border: 1px solid #28262c;
          color: #333;
          img{
            width: 60px;
            height: 60px;
            background: #FFFFFF;
            border-radius: 50%;
            margin-right: 11px;
          }
          p{
            margin-right: 8px;
            line-height: 25px;
          }
        }
      }
    }
    .msgBox{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height:59px;
      border-radius:0 0 10px 10px;
      background:rgba(40,38,44,1);
      line-height: 59px;
      font-size:14px;
      text-align: center;
      color:rgba(116,119,132,1);
    }
  }
}
</style>

